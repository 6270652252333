<template>
  <b-card
    :img-src="require(`@/assets/images/banner/banner-42-${skin || 'dark'}.jpg`)"
    img-alt="Profile Cover Photo"
    img-top
    img-height="100"
    class="card-profile"
  >
    <div class="profile-image-wrapper">
      <div class="profile-image p-0">
        <b-avatar
          :src="subjectData.avatar"
          :text="avatarText(subjectData.name)"
          :variant="`light-${resolveSubjectStatusVariant(subjectData.status)}`"
          size="104px"
        />
      </div>
    </div>
    <h3>{{ subjectData.name }}</h3>
    <h6 class="text-muted">
      <b-badge class="profile-badge m-0" variant="light-primary">
        {{ title(subjectData.status + ` | ` + subjectData.visibility) }}
      </b-badge>
    </h6>
    <hr class="mb-2" />

    <!-- follower projects rank -->
    <div v-if="false" class="d-flex justify-content-between align-items-center">
      <div>
        <h6 class="text-muted font-weight-bolder">Total Students</h6>
        <h3 class="mb-0">
          {{ 0 }}
        </h3>
      </div>
      <div>
        <h6 class="text-muted font-weight-bolder">Total Courses</h6>
        <h3 class="mb-0">
          {{ 0 }}
        </h3>
      </div>
      <div>
        <h6 class="text-muted font-weight-bolder">Total Courses</h6>
        <h3 class="mb-0">
          {{ 0 }}
        </h3>
      </div>
    </div>

    <b-row class="mt-1">
      <b-button :to="{ name: 'apps-roster-subject-edit', params: { id: subjectData.id } }" variant="primary" size="sm" block>
        Edit Subject
      </b-button>
      <b-button
        :to="{ name: 'apps-roster-profile-list', query: { subject: subjectData.id, ignoreDate: true } }"
        variant="outline-primary"
        size="sm"
        block
      >
        View Waitlist
      </b-button>
    </b-row>
    <!--/ follower projects rank -->
  </b-card>
</template>

<script>
import { BCard, BButton, BAvatar, BRow, BCol, BBadge } from 'bootstrap-vue';
import { avatarText } from '@core/utils/filter';
import useSubjectsList from '../subjects-list/useSubjectsList';
import { title } from '@core/utils/filter';
import usAppConfig from '@core/app-config/useAppConfig';

const { skin } = usAppConfig();

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BBadge,
  },
  props: {
    subjectData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { resolveSubjectStatusVariant } = useSubjectsList();
    return {
      avatarText,
      resolveSubjectStatusVariant,
      title,
      skin,
    };
  },
};
</script>

<style></style>
