<template>
  <b-sidebar
    id="add-new-course-sidebar"
    :visible="isAddNewCourseSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-course-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">Add New Course</h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
          <!-- Name -->
          <validation-provider #default="validationContext" name="Course Name" rules="required">
            <b-form-group label="Course Name" label-for="course-name">
              <b-form-input
                id="course-name"
                v-model="courseData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="DevOps"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Visibility -->
          <validation-provider #default="validationContext" name="Visibility" rules="required">
            <b-form-group label="Visibility" label-for="visibility" :state="getValidationState(validationContext)">
              <v-select
                v-model="courseData.visibility"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="visibilityOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="course-visibility"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Status -->
          <validation-provider #default="validationContext" name="Status" rules="required">
            <b-form-group label="Status" label-for="status" :state="getValidationState(validationContext)">
              <v-select
                v-model="courseData.status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="course-status"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
              Add
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary" @click="hide">
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ref } from '@vue/composition-api';
import { required, alphaNum, email } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import countries from '@/@fake-db/data/other/countries';
import store from '@/store';

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewCourseSidebarActive',
    event: 'update:is-add-new-course-sidebar-active',
  },
  props: {
    isAddNewCourseSidebarActive: {
      type: Boolean,
      required: true,
    },
    visibilityOptions: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    };
  },
  setup(props, { emit }) {
    const blankCourseData = {
      name: '',
      visibility: 'internal',
      status: 'created',
    };

    const courseData = ref(JSON.parse(JSON.stringify(blankCourseData)));
    const resetcourseData = () => {
      courseData.value = JSON.parse(JSON.stringify(blankCourseData));
    };

    const onSubmit = () => {
      store.dispatch('app-roster/addCourse', courseData.value).then(() => {
        emit('refetch-data');
        emit('update:is-add-new-course-sidebar-active', false);
      });
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetcourseData);

    return {
      courseData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-course-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
